import React from 'react';
import { IntlProvider } from 'react-intl';
import { isLocale } from 'language/messages';

const LanguageContext = React.createContext({} as any);

export const LanguageProvider = ({ children, messages }) => {
  const [locale, setLocale] = React.useState('fr');
  const changeLanguage = (newLocale): void => {
    setLocale(newLocale);
    document.documentElement.lang = newLocale;
    localStorage.setItem('locale', newLocale);
  };
  React.useEffect(() => {
    const localSetting = localStorage.getItem('locale');
    if (localSetting && isLocale(localSetting)) {
      document.documentElement.lang = localSetting;
      setLocale(localSetting);
    }
  }, [locale]);

  return (
    <LanguageContext.Provider value={{ locale, changeLanguage }}>
      <IntlProvider locale={locale} messages={messages[locale]}>
            {children}
      </IntlProvider>
    </LanguageContext.Provider>
  );
};

export const useLocale = () => React.useContext(LanguageContext);
