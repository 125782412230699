import ReactDOM from 'react-dom';
import { ToastContainer } from 'react-toastify';
import dayjs from 'dayjs';
import 'dayjs/locale/fr'
import { BrowserRouter } from 'react-router-dom';
import { Client as Styletron } from 'styletron-engine-atomic';
import { Provider as StyletronProvider } from 'styletron-react';
import { StoreProvider } from 'context/StoreContext';
import { ThemeProvider, ProvidedThemeProvider } from 'context/ThemeContext';
import { LanguageProvider } from 'context/LanguageContext';
import { messages } from 'language/messages'
import Routes from './routes';
import * as serviceWorker from './serviceWorker';
import 'react-toastify/dist/ReactToastify.min.css';
import './theme/global.css';
import { AuthProvider } from "react-oidc-context";
const oidcConfig = {
  authority: "https://auth.ormenio.com/realms/ormenio",
  client_id: "restaurant",
  redirect_uri: "https://owner.ormenio.com",
  onSigninCallback: (user): void => {
    window.history.replaceState({}, document.title, window.location.pathname);
    window.location = user.state || "/";
    }
};
dayjs.locale('fr') 

function App() {
  const engine = new Styletron();

  return (
    <AuthProvider {...oidcConfig}>
      <StoreProvider>
        <StyletronProvider value={engine}>
          <LanguageProvider messages={messages}>
            <ThemeProvider>
              <ProvidedThemeProvider>
                <BrowserRouter>
                  <Routes />
                  <ToastContainer autoClose={8000} position="top-right" />
                </BrowserRouter>
              </ProvidedThemeProvider>
            </ThemeProvider>
          </LanguageProvider>
        </StyletronProvider>
      </StoreProvider>
    </AuthProvider>
  );
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
