import { BaseProvider, createTheme } from 'baseui';
import { createCtx } from './create-context';
import { primitives as defaultPrimitives, overrides as defaultOverrides, colorsOverrides as DefaultColorsOverrides } from '../theme';

const initialState = {
  theme: {
    primitives: defaultPrimitives,
    overrides: defaultOverrides,
    colorsOverrides: DefaultColorsOverrides,
  }
};
type State = typeof initialState;
type Action = any;
function reducer(state: State, action: Action) {
  switch (action.type) {
    case 'SET_THEME':{
      const colorsOverrides = {
        ...state.theme.colorsOverrides,
        primary: action.primary,
        primary700: action.primary,
      };
      return {
        ...state,
        theme: {
          ...state.theme,
          primitives: {
            ...state.theme.primitives,
            primary: action.primary,
            primary700: action.primary,
          },
          overrides: {
            ...state.theme.overrides,
            colors: { ...colorsOverrides },
          },
          colorsOverrides,
         }
        }
      };
    default:
      return state;
  }
}
const [useThemeState, useThemeDispatch, ThemeProvider] = createCtx(
  initialState,
  reducer
);

function ProvidedThemeProvider({ children }: React.PropsWithChildren<{}>) {
  const { theme } = useThemeState();
  const  { primitives, overrides} = theme;
  const providedTheme = createTheme(primitives, overrides);

  return (
    <BaseProvider theme={providedTheme}>
        {children}
    </BaseProvider>
  );
}

export { useThemeState, useThemeDispatch, ProvidedThemeProvider, ThemeProvider };
