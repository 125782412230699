import { getActionTypes, callApiWithActions } from "api";


const loadCouponsActions = getActionTypes("LOAD_COUPONS");
const removeCouponActions = getActionTypes("REMOVE_COUPON");
const createCouponActions = getActionTypes("CREATE_COUPON");
export const RESET_CREATE_COUPON_ACTION = "RESET_CREATE_COUPON_ACTION";

const actionInitState = {
    loading: false,
    error: false,
    success: false,
};

export const initialState = {
    list: {
        ...actionInitState,
        data: null,
    },
    remove: {
        ...actionInitState
    },
    add: {
        ...actionInitState
    }
};

export function couponsReducer(state = initialState, action) {
    switch (action.type) {
        case loadCouponsActions.begin:
            return {
                ...state,
                list: {
                    loading: true,
                    error: false,
                    loaded: false,
                    data: null,
                }
            };
        case loadCouponsActions.success: {
            return {
                ...state,
                list: {
                    loading: false,
                    error: false,
                    loaded: true,
                    data: action.data,
                }
            };
        };
        case loadCouponsActions.error:
            return {
                ...state,
                list: {
                    loading: false,
                    error: true,
                    loaded: false,
                    data: null
                }
            };
        case removeCouponActions.begin:
            return {
                ...state,
                remove: {
                    success: undefined,
                    error: undefined,
                }
            };
        case removeCouponActions.success:
            return {
                ...state,
                remove: {
                    success: true,
                    error: false,
                }
            };
        case removeCouponActions.failure:
            return {
                ...state,
                remove: {
                    success: false,
                    error: true,
                }
            };
        case createCouponActions.begin:
            return {
                ...state,
                add: {
                    loading: true,
                    error: false,
                    success: false,
                }
            };
        case createCouponActions.success:
            return {
                ...state,
                add: {
                    loading: false,
                    error: false,
                    success: true,
                }
            };
        case createCouponActions.error:
            return {
                ...state,
                add: {
                    loading: false,
                    error: true,
                    success: false,
                }
            };
        case RESET_CREATE_COUPON_ACTION:
            return {
                ...state,
                add: {
                    ...actionInitState,
                }
            };
        default:
            return state;
    }
}

export const getRestaurantCoupons = (dispatch, restaurantId) => {
    callApiWithActions(
        dispatch,
        `/admin/coupons/?restaurantId=${restaurantId}`,
        {
            method: "GET",
        },
        loadCouponsActions
    );
}

export const removeCoupon = (dispatch, couponId) => {
    callApiWithActions(
        dispatch,
        `/admin/coupons/${couponId}`,
        {
            method: "PATCH",
        },
        removeCouponActions
    );
}

export const createCoupon = (dispatch, config) => {
    const { data } = config;
    callApiWithActions(
        dispatch,
        '/admin/coupons/',
        {
            method: "POST",
            data
        },
        createCouponActions
    );
}

export const addSelector = (state) => state.coupons.add;
export const listSelector = (state) => state.coupons.list;
export const removeCouponSelector = (state) => state.coupons.remove;
