import * as yup from "yup";
import { getActionTypes, callApiWithActions } from "api";


const createCategoryActions = getActionTypes("CREATE_CATEGOY");
const updateCategoryActions = getActionTypes("UPDATE_CATEGOY");
const deleteCategoryActions = getActionTypes("DELETE_CATEGOY");
export const RESET_CREATE_CATEGORY_ACTION = "RESET_CREATE_CATEGORY_ACTION";
export const RESET_UPDATE_CATEGORY_ACTION = "RESET_UPDATE_CATEGORY_ACTION";

const actionInitState = {
    loading: false,
    error: false,
    success: false,
};

export const initialState = {
    add: {
        ...actionInitState,
    },
    update: {
        ...actionInitState,
    },
    delete: {
        ...actionInitState,
    }
};


export function categoriesReducer(state = initialState, action) {
    switch (action.type) {
        case createCategoryActions.begin:
            return {
                ...state,
                add: {
                    loading: true,
                    error: false,
                    success: false,
                }
            };
        case updateCategoryActions.begin:
            return {
                ...state,
                update: {
                    loading: true,
                    error: false,
                    success: false,
                }
            };
        case createCategoryActions.success:
            return {
                ...state,
                add: {
                    loading: false,
                    error: false,
                    success: true,
                }
            };
        case updateCategoryActions.success:
            return {
                ...state,
                update: {
                    loading: false,
                    error: false,
                    success: true,
                }
            };
        case deleteCategoryActions.success:
            return {
                ...state,
                delete: {
                    loading: false,
                    error: false,
                    success: true,
                }
            };
        case createCategoryActions.error:
            return {
                ...state,
                add: {
                    loading: false,
                    error: true,
                    success: false,
                }
            };
        case updateCategoryActions.error:
            return {
                ...state,
                update: {
                    loading: false,
                    error: true,
                    success: false,
                }
            };
        case deleteCategoryActions.error:
            return {
                ...state,
                delete: {
                    loading: false,
                    error: true,
                    success: false,
                }
            };
        case RESET_CREATE_CATEGORY_ACTION:
            return {
                ...state,
                add: {
                    ...actionInitState,
                }
            };
        case RESET_UPDATE_CATEGORY_ACTION:
            return {
                ...state,
                update: {
                    ...actionInitState,
                },
                delete: {
                    ...actionInitState,
                }
            };
        default:
            return state;
    }
}


export const createCategory = (dispatch, config) => {
    const { restaurantId, data } = config;
    callApiWithActions(
        dispatch,
        `/admin/restaurants/${restaurantId}/categories/new`,
        {
            method: "POST",
            data
        },
        createCategoryActions
    );
}

export const updateCategory = (dispatch, config) => {
    const { categoryId, data } = config;
    callApiWithActions(
        dispatch,
        `/admin/categories/${categoryId}`,
        {
            method: "PUT",
            data
        },
        updateCategoryActions
    );
}

export const deleteCategory = (dispatch, config) => {
    const { categoryId } = config;
    callApiWithActions(
        dispatch,
        `/admin/categories/${categoryId}`,
        {
            method: "DELETE",
        },
        deleteCategoryActions
    );
}



export const addSelector = (state) => state.categories.add;
export const updateSelector = (state) => state.categories.update;
export const deleteSelector = (state) => state.categories.delete;

export const listIcons = ['FruitsVegetable', 'MeatFish', 'Snacks', 'Cooking', 'Breakfast', 'Beverage', 'Pizza', 'Sushi', 'Sandwich', 'Fish', 'Spaghetti', 'Cake', 'Steak', 'Chicken', 'Salad', 'Taco'];

export const categorySchema =
    yup.object().shape({
        label: yup.string()
                  .max(50, 'Taille maximum de 50 caractères')
                  .required("Champ obligatoire"),
        description: yup.string()
                 .max(255, 'Taille maximum de 255 caractères'),
        icon: yup.string()
              .required("Champ obligatoire"),
});
   
   