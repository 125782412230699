import { getActionTypes, callApiWithActions } from "api";


const loadTablesActions = getActionTypes("LOAD_TABLES");
const removeTablesActions = getActionTypes("REMOVE_TABLE");
const addTablesActions = getActionTypes("ADD_TABLE");
export const RESET_ADD_TABLE_ACTION = "RESET_ADD_TABLE_ACTION";
export const RESET_REMOVE_TABLE_ACTION = "RESET_REMOVE_TABLE_ACTION";

const actionInitState = {
    loading: false,
    error: false,
    success: false,
};

export const initialState = {
    list: {
        ...actionInitState,
        data: null
    },
    remove: {
        ...actionInitState
    },
    add: {
        ...actionInitState
    }
};

export function tablesReducer(state = initialState, action) {
    switch (action.type) {
        case loadTablesActions.begin:
            return {
                ...state,
                list: {
                    loading: true,
                    error: false,
                    success: false,
                    data: null,
                }
            };
        case loadTablesActions.success:
            return {
                ...state,
                list: {
                    loading: false,
                    error: false,
                    success: true,
                    data: action.data,
                }
            };
        case loadTablesActions.error:
            return {
                ...state,
                list: {
                    loading: false,
                    error: true,
                    success: false,
                    data: null
                }
            };
        case removeTablesActions.begin:
            return {
                ...state,
                remove: {
                    loading: true,
                    error: false,
                    success: false,
                }
            };
        case removeTablesActions.success:
            return {
                ...state,
                remove: {
                    loading: false,
                    error: false,
                    success: true,
                }
            };
        case removeTablesActions.error:
            return {
                ...state,
                remove: {
                    loading: false,
                    error: true,
                    success: false,
                }
            };

        case addTablesActions.begin:
            return {
                ...state,
                add: {
                    loading: true,
                    error: false,
                    success: false,
                }
            };
        case addTablesActions.success:
            return {
                ...state,
                add: {
                    loading: false,
                    error: false,
                    success: true,
                }
            };
        case addTablesActions.error:
            return {
                ...state,
                add: {
                    loading: false,
                    error: true,
                    success: false,
                }
            };
        case RESET_ADD_TABLE_ACTION:
            return {
                ...state,
                add: actionInitState,
            };
        case RESET_REMOVE_TABLE_ACTION:
            return {
                ...state,
                remove: actionInitState,
            };
        default:
            return state;
    }
}

export const getRestaurantTables = (dispatch, restaurantId) => {
    callApiWithActions(
        dispatch,
        `/admin/restaurants/${restaurantId}/tables`,
        {
            method: "GET",
        },
        loadTablesActions
    );
}

export const removeTable = (dispatch, tableId) => {
    callApiWithActions(
        dispatch,
        `/admin/tables/${tableId}`,
        {
            method: "DELETE",
        },
        removeTablesActions
    );
}

export const addTable = (dispatch, restaurantId) => {
    callApiWithActions(
        dispatch,
        `/admin/restaurants/${restaurantId}/tables/new`,
        {
            method: "POST",
        },
        addTablesActions
    );
}

export const listSelector = (state) => state.tables.list;
export const removeTableSelector = (state) => state.tables.remove;
export const addTableSelector = (state) => state.tables.add;
