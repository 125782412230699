import { getActionTypes, callApiWithActions } from "api";


const updateRestaurantActions = getActionTypes("UPDATE_RESTAURANT_SETTINGS");
export const RESET_UPDATE_SETTINGS_ACTION = "RESET_UPDATE_SETTINGS_ACTION";

const actionInitState = {
    loading: false,
    error: false,
    success: false,
};

export const initialState = {
    update: {
        ...actionInitState,
    }
};


export function restaurantSettingsReducer(state = initialState, action) {
    switch (action.type) {

        case updateRestaurantActions.begin:
            return {
                ...state,
                update: {
                    loading: true,
                    error: false,
                    success: false,
                }
            };
        case updateRestaurantActions.success:
            return {
                ...state,
                update: {
                    loading: false,
                    error: false,
                    success: true,
                }
            };
        case updateRestaurantActions.error:
            return {
                ...state,
                update: {
                    loading: false,
                    error: true,
                    success: false,
                }
            };
        case RESET_UPDATE_SETTINGS_ACTION:
            return {
                ...state,
                update: {
                    ...actionInitState,
                }
            };
        default:
            return state;
    }
}



export const updateRestaurant = (dispatch, config) => {
    const { restaurantId, data } = config;
    callApiWithActions(
        dispatch,
        `/admin/restaurants/${restaurantId}`,
        {
            method: "PUT",
            headers: {
                'Content-Type': `multipart/form-data;`,
            },
            data
        },
        updateRestaurantActions
    );
}

export const updateSelector = (state) => state.restaurantSettings.update;
