import axios from "axios";
import { baseUrl } from "settings/constants";

const instance = axios.create({
  baseURL: baseUrl,
});

/**
 * Axios Request Interceptor
 */
instance.interceptors.request.use(config => {
  const token = JSON.parse(sessionStorage.getItem("oidc.user:https://auth.ormenio.com/realms/ormenio:restaurant")).access_token;

  config.headers["Access-Control-Allow-Origin"] = "*";
  config.headers["Authorization"] = `Bearer ${token}`;

  return config;
}, error => {
  console.debug("axios request interceptor error", error);
  return Promise.reject(error);
});

export default instance;
