import * as yup from "yup";
import { getActionTypes, callApiWithActions } from "api";

const loadWaitersActions = getActionTypes("LOAD_WAITERS");
const createWaiterActions = getActionTypes("CREATE_WAITER");
const updateWaiterActions = getActionTypes("UPDATE_WAITER");
const deleteWaiterActions = getActionTypes("DELETE_WAITER");
export const RESET_CREATE_WAITER_ACTION = "RESET_CREATE_WAITER_ACTION";
export const RESET_UPDATE_WAITER_ACTION = "RESET_UPDATE_WAITER_ACTION";


const actionInitState = {
    loading: false,
    error: false,
    success: false,
};

export const initialState = {
    list: {
        loading: false,
        error: false,
        loaded: false,
        data: null,
    },
    add: {
        ...actionInitState,
    },
    update: {
        ...actionInitState,
    },
    delete: {
        ...actionInitState,
    }
};


export function waitersReducer(state = initialState, action) {
    switch (action.type) {
        case loadWaitersActions.begin:
            return {
                ...state,
                list: {
                    loading: true,
                    error: false,
                    loaded: false,
                    data: null,
                }
            };
        case loadWaitersActions.success: {
            return {
                ...state,
                list: {
                    loading: false,
                    error: false,
                    loaded: true,
                    data: action.data,
                }
            };
        };
        case loadWaitersActions.error:
            return {
                ...state,
                list: {
                    loading: false,
                    error: true,
                    loaded: false,
                    data: null
                }
            };
        case createWaiterActions.begin:
            return {
                ...state,
                add: {
                    loading: true,
                    error: false,
                    success: false,
                }
            };
        case createWaiterActions.success:
            return {
                ...state,
                add: {
                    loading: false,
                    error: false,
                    success: true,
                }
            };
        case createWaiterActions.error:
            return {
                ...state,
                add: {
                    loading: false,
                    error: true,
                    success: false,
                }
            };
        case updateWaiterActions.begin:
            return {
                ...state,
                update: {
                    loading: true,
                    error: false,
                    success: false,
                }
            };
            case updateWaiterActions.success:
                return {
                    ...state,
                    update: {
                        loading: false,
                        error: false,
                        success: true,
                    }
                };
            case updateWaiterActions.error:
                return {
                    ...state,
                    update: {
                        loading: false,
                        error: true,
                        success: false,
                    }
                };
            case RESET_CREATE_WAITER_ACTION:
                return {
                    ...state,
                    add: {
                        ...actionInitState,
                    }
                };
            case RESET_UPDATE_WAITER_ACTION:
                return {
                    ...state,
                    update: {
                        ...actionInitState,
                    }
                };
                    case loadWaitersActions.begin:
            return {
                ...state,
                list: {
                    loading: true,
                    error: false,
                    loaded: false,
                    data: null,
                }
            };
        case loadWaitersActions.success: {
            return {
                ...state,
                list: {
                    loading: false,
                    error: false,
                    loaded: true,
                    data: action.data,
                }
            };
        };
        case loadWaitersActions.error:
            return {
                ...state,
                list: {
                    loading: false,
                    error: true,
                    loaded: false,
                    data: null
                }
            };        
        case deleteWaiterActions.begin:
            return {
                ...state,
                delete: {
                    loading: true,
                    error: false,
                    success: false,
                }
            };
        case deleteWaiterActions.success: {
            return {
                ...state,
                delete: {
                    loading: false,
                    error: false,
                    success: true,
                }
            };
        };
        case deleteWaiterActions.error:
            return {
                ...state,
                delete: {
                    loading: false,
                    error: true,
                    success: false,
                }
            };
        default:
            return state;
    }
}

export const getWaiters = (dispatch, {restaurantId, params, employeeType}) => {
    let searchParams = '?';
    const url = employeeType === 'DELIVERY_MAN' ?
     `/admin/restaurants/${restaurantId}/delivery_men/${searchParams}` : 
     `/admin/restaurants/${restaurantId}/employees/${searchParams}&role=${employeeType}`
     ;
    for(const key in params) {
        searchParams = searchParams + key + '=' + params[key] + '&';
    }
    callApiWithActions(
        dispatch,
        url,
        {
            method: "GET",
        },
        loadWaitersActions
    );
}

export const createWaiter = (dispatch, config) => {
    const { data } = config;
    callApiWithActions(
        dispatch,
        `/admin/employees/new`,
        {
            method: "POST",
            data
        },
        createWaiterActions
    );
}

export const deleteWaiter = (dispatch, config) => {
    const { waiterId } = config;
    callApiWithActions(
        dispatch,
        `/admin/users/${waiterId}`,
        {
            method: "DELETE",
        },
        deleteWaiterActions
    );
}

export const updateWaiter = (dispatch, config) => {
    const { waiterId, data } = config;
    callApiWithActions(
        dispatch,
        `/admin/users/${waiterId}`,
        {
            method: "PATCH",
            data
        },
        updateWaiterActions
    );
}



export const addSelector = (state) => state.waiters.add;
export const updateSelector = (state) => state.waiters.update;
export const listSelector = (state) => state.waiters.list;
export const deleteSelector = (state) => state.waiters.delete;

const EMAIL_REGEX = /^[a-zA-Z0-9\._-]+@[a-zA-Z0-9\.-]+\.[a-zA-Z]{2,63}$/;

export const commonSchema = {
    firstName: yup.string()
    .max(40, 'Taille maximum de 40 caractères')
    .required("Champ obligatoire"),
    lastName: yup.string()
                .max(40, 'Taille maximum de 40 caractères')
                .required("Champ obligatoire"),
    gsm: yup.string()
            .max(15, 'Taille maximum de 15 caractères')
            .required("Champ obligatoire"),
};

export const creationSchema = yup.object().shape({
    ...commonSchema
});

export const updateSchema = yup.object().shape({
    ...commonSchema,
    password: yup.string().max(100, 'Taille maximum de 100 caractères'),
});
