import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useStoreState, useStoreDispatch } from 'context/StoreContext';
import { loadUserContext } from './behavior';
import { useAuth } from "react-oidc-context";

export default function AppProvider(props) {
    const state = useStoreState();
    const { userContextLoaded, useContextError } = state.userContext;
    const dispatch = useStoreDispatch();
    const auth = useAuth();

    const _bootstrap = async () => {
        if (auth.isAuthenticated) {
            loadUserContext(dispatch);
        } else {
            auth.signinRedirect();
        }
    }

    useEffect(() => {
        _bootstrap();
    }, []);

    if (userContextLoaded)
        return <>{props.children}</>;
    return <div><FormattedMessage id="loading" defaultMessage="Loading.." /></div>;
};
