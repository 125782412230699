import { getActionTypes, callApiWithActions } from "api";


const passwordUpdateActions = getActionTypes("PASSWORD_UPDATE");
export const RESET_PASSWORD_UPDATE_ACTION = "RESET_PASSWORD_UPDATE_ACTION";

const actionInitState = {
    loading: false,
    error: false,
    success: false,
};

export const initialState = {
    update: {
        ...actionInitState,
    }
};


export function passwordUpdateReducer(state = initialState, action) {
    switch (action.type) {
        case passwordUpdateActions.begin:
            return {
                ...state,
                update: {
                    loading: true,
                    error: false,
                    success: false,
                }
            };
        case passwordUpdateActions.success:
            return {
                ...state,
                update: {
                    loading: false,
                    error: false,
                    success: true,
                }
            };
        case passwordUpdateActions.error:
            return {
                ...state,
                update: {
                    loading: false,
                    error: true,
                    success: false,
                }
            };
        case RESET_PASSWORD_UPDATE_ACTION:
            return {
                ...state,
                update: {
                    ...actionInitState,
                }
            };
        default:
            return state;
    }
}



export const updatePassword = (dispatch, config) => {
    const { data } = config;
    callApiWithActions(
        dispatch,
        `/admin/users/updatePassword`,
        {
            method: "POST",
            data
        },
        passwordUpdateActions
    );
}

export const updateSelector = (state) => state.passwordUpdate.update;
