import { initialState as loginInitalState, loginReducer } from 'containers/Login/behavior';
import { initialState as layoutInitialState, layoutReducer } from 'containers/Layout/behavior';
import { initialState as userContextInitialState, userContextReducer } from 'containers/AppProvider/behavior';
import { initialState as categoriesItemsInitialState, categoriesItemsReducer } from 'containers/Products/behavior';
import { initialState as restaurantSettingsInitialState, restaurantSettingsReducer } from 'containers/SiteSettingForm/behavior';
import { initialState as passwordUpdateInitialState, passwordUpdateReducer } from 'containers/PasswordUpdate/behavior';
import { initialState as categoriesInitialState, categoriesReducer } from 'containers/Category/behavior';
import { initialState as reviewsInitialState, reviewsReducer } from 'containers/Reviews/behavior';
import { initialState as ordersInitialState, ordersReducer } from 'containers/Orders/behavior';
import { initialState as couponsInitialState, couponsReducer } from 'containers/Coupons/behavior';
import { initialState as tablesInitialState, tablesReducer } from 'containers/Tables/behavior';
import { initialState as waitersInitialState, waitersReducer } from 'containers/Waiters/behavior';

import { createCtx } from './create-context';

const combineReducers = (nodes) => (state, action) => {
    return Object.keys(nodes).reduce(
        (innerState, node) => ({
            ...innerState,
            [node]: nodes[node](innerState[node], action),
        }),
        state
    );
}

const initialState = {
    loginState: loginInitalState,
    userContext: userContextInitialState,
    passwordUpdate: passwordUpdateInitialState,
    layout: layoutInitialState,
    categoriesItems: categoriesItemsInitialState,
    categories: categoriesInitialState,
    restaurantSettings: restaurantSettingsInitialState,
    reviews: reviewsInitialState,
    orders: ordersInitialState,
    coupons: couponsInitialState,
    tables: tablesInitialState,
    waiters: waitersInitialState
};
const rootReducer = combineReducers({
    loginState: loginReducer,
    userContext: userContextReducer,
    passwordUpdate: passwordUpdateReducer,
    layout: layoutReducer,
    categoriesItems: categoriesItemsReducer,
    categories: categoriesReducer,
    restaurantSettings: restaurantSettingsReducer,
    reviews: reviewsReducer,
    orders: ordersReducer,
    coupons: couponsReducer,
    tables: tablesReducer,
    waiters: waitersReducer,
});

const [useStoreState, useStoreDispatch, StoreProvider] = createCtx(
    initialState,
    rootReducer
);

export { useStoreState, useStoreDispatch, StoreProvider };
