import { toast } from 'react-toastify';
import { START_LOADER, STOP_LOADER } from 'containers/Layout/behavior';
import request from "./request";

export function callApiWithActions(
  dispatch,
  url,
  config,
  action,
  params = {},
){
  dispatch({ type: action.begin, ...params });
  dispatch({ type: START_LOADER });
  return request(url, config)
    .then((response) => {
      dispatch({ type: STOP_LOADER });
      return dispatch({ type: action.success, data: response.data, ...params });
    })
    .catch((error) => {
      dispatch({ type: STOP_LOADER });
      dispatch({ type: action.error, error, ...params });
      toast.error(getErrorMessage(error),  { autoClose: false });
      return Promise.reject(error);
    });
};

export const getActionTypes = (action) => {
  return {
    begin: `${action}_BEGIN`,
    success: `${action}_SUCCESS`,
    error: `${action}_ERROR`,
  };
};

/**
 *
 * @param url
 * @param config
 * @returns {Promise<AxiosResponse<any>>}
 */
export const callApi = (url, config) => {
  return request(url, config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const getSearchParams = (params) => {
  let searchParams = '?';
  for(const key in params) {
      searchParams = searchParams + key + '=' + params[key] + '&';
  }

  return searchParams;
}

export const getErrorMessage = (error) => {
  const defaultMessage = 'Une erreur a survenu';

  if(error.response)
    return  error.response.data? error.response.data.errorMsg: defaultMessage;
  return defaultMessage;
}
