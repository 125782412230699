// **************** ROUTE CONSTANT START **************************
// General Page Section
export const DASHBOARD = '/';
export const PRODUCTS = '/products';
export const CATEGORY = '/category';
export const LOGIN = '/login';
export const LOGOUT = '/logout';
export const ORDERS = '/orders';
export const COUPONS = '/coupons';
export const SETTINGS = '/settings';
export const SITE_SETTINGS = '/site-settings';
export const REVIEWS = '/reviews';
export const TABLES = '/tables';
export const WAITERS = '/waiters';
// **************** ROUTE CONSTANT END **************************

export const CURRENCY = 'MAD';
export const MAP_DEFAULT_RADIUS = process.env.REACT_APP_MAP_DEFAULT_RADIUS;

export const baseUrl = process.env.REACT_APP_API_URL;
export const authUrl = process.env.REACT_APP_AUTH_URL;
export const authRealm = process.env.REACT_APP_AUTH_REALM;
export const authClient = process.env.REACT_APP_AUTH_CLIENT;


export const LANGUAGE_MENU = [
    {
      id: 'fr',
      icon: 'FRFlag',
    },
    {
      id: 'en',
      icon: 'USFlag',
    },
  ];
  
