import localFr from './lang/fr.json';
import localEn from './lang/en.json';

export const messages = {
  fr: localFr,
  en: localEn,
};

export const locales = ['fr', 'en'];
export const defaultLocale = 'fr' as const;

export const isLocale = (tested) => {
  return locales.some((locale) => locale === tested);
}

export const getInitialLocale = () => {
  const localSetting = localStorage.getItem('locale');
  if (localSetting && isLocale(localSetting)) {
    return localSetting;
  }

  return defaultLocale;
}

