import { callApi, getActionTypes } from "api";


const userContextActions = getActionTypes("USER_CONTEXT");

export const initialState = {
    userContextLoading: false,
    useContextError: false,
    userContextLoaded: false,
    userContext: {}
};


export function userContextReducer(state = initialState, action) {
    switch (action.type) {
        case userContextActions.begin:
            return {
                ...state,
                userContextLoading: true,
                useContextError: false,
                userContextLoaded: false,
                userContext: {}
            };

        case userContextActions.success:
            return {
                ...state,
                userContextLoading: false,
                useContextError: false,
                userContextLoaded: true,
                userContext: action.context
            };

        case userContextActions.error:
            return {
                ...state,
                userContextLoading: false,
                useContextError: true,
                userContextLoaded: false,
                userContext: {}
            };
        default:
            return state;
    }
}

export const loadUserContext = async (dispatch) => {
    try {
        dispatch({ type: userContextActions.begin });
        const currentUser = await callApi("/admin/users/me");
        const userRestaurants = await callApi(`/admin/restaurants/?userId=${currentUser.id}`);
        dispatch({
            type: userContextActions.success,
            context: { ...currentUser, restaurants: userRestaurants }
        })
    } catch (e) {
        dispatch({ type: userContextActions.error });
    }
}
