import { toast } from 'react-toastify';
import { callApi, getActionTypes, getErrorMessage } from "api";


const LOGIN_USER = getActionTypes("LOGIN_USER");
export const RESET_LOGIN_PAGE = "RESET_LOGIN_PAGE";

export const initialState = {
    loading: false,
    errorOccured: false,
    loggedIn: false,
    errorCode: '',
};


export function loginReducer(state = initialState, action) {
    switch (action.type) {
        case LOGIN_USER.begin:
            return {
                ...state,
                loading: true,
                errorOccured: false,
                loggedIn: false,
                errorCode: '',
            };

        case LOGIN_USER.success:
            return {
                ...state,
                loading: false,
                errorOccured: false,
                loggedIn: true,
                errorCode: '',
            };

        case LOGIN_USER.error:
            return {
                ...state,
                loading: false,
                errorOccured: true,
                loggedIn: false,
                errorCode: action.error ? action.error.code : ''
            };
        case RESET_LOGIN_PAGE:
            return {
                ...state,
                loading: false,
                errorOccured: false,
                loggedIn: false,
                errorCode: ''
            };
        default:
            return state;
    }
}

export const authenticate = (dispatch, { email, password }, cb) => {
    dispatch({ type: LOGIN_USER.begin });
    callApi("/auth/signin", {
        data: { email, password },
        method: "POST",
    })
        .then(function (response) {
            localStorage.setItem("access_token", response.accessToken);
            dispatch({ type: LOGIN_USER.success });
            cb();
        })
        .catch(function (error) {
            //handle error
            toast.error(getErrorMessage(error),  { autoClose: false });
            dispatch({ type: LOGIN_USER.error, error: error.response ? error.response.data : {} });
        });
}


export const logout = (cb) => {
    localStorage.removeItem("access_token");
    cb();
};
