import { lazy, Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import {
  LOGIN,
  PRODUCTS,
  CATEGORY,
  DASHBOARD,
  ORDERS,
  REVIEWS,
  SETTINGS,
  COUPONS,
  SITE_SETTINGS,
  TABLES,
  WAITERS
} from 'settings/constants';
import { useAuth } from "react-oidc-context";
import { InLineLoader } from 'components/InlineLoader/InlineLoader';
import AppProvider from 'containers/AppProvider';
const Products = lazy(() => import('containers/Products/Products'));
const AdminLayout = lazy(() => import('containers/Layout/Layout'));
const Dashboard = lazy(() => import('containers/Dashboard/Dashboard'));
const Category = lazy(() => import('containers/Category/Category'));
const Orders = lazy(() => import('containers/Orders/Orders'));
const Settings = lazy(() => import('containers/Settings/Settings'));
const Tables = lazy(() => import('containers/Tables/Tables'));
const Waiters = lazy(() => import('containers/Waiters/Waiters'));
const SiteSettingForm = lazy(() =>
  import('containers/SiteSettingForm/SiteSettingForm')
);
const Coupons = lazy(() => import('containers/Coupons/Coupons'));
const Login = lazy(() => import('containers/Login/Login'));
const Reviews = lazy(() => import('containers/Reviews/Reviews'));
const NotFound = lazy(() => import('containers/NotFound/NotFound'));

/**
 *
 *  A wrapper for <Route> that redirects to the login
 * screen if you're not yet authenticated.
 *
 */

function PrivateRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={matchProps => (
        <AppProvider {...matchProps}>
          {children}
        </AppProvider>
      )}
    />
  );
}

const Routes = () => {
  const auth = useAuth();

  if (auth.isLoading) {
    return <InLineLoader />;
  }
  if (auth.error) {
    return <div>Oops... {auth.error.message}</div>;
  }
  return (
    <Suspense fallback={<InLineLoader />}>
      <Switch>
        <PrivateRoute exact={true} path={DASHBOARD}>
          <AdminLayout>
            <Suspense fallback={<InLineLoader />}>
              <Dashboard />
            </Suspense>
          </AdminLayout>
        </PrivateRoute>
        <PrivateRoute path={PRODUCTS}>
          <AdminLayout>
            <Suspense fallback={<InLineLoader />}>
              <Products />
            </Suspense>
          </AdminLayout>
        </PrivateRoute>
        <PrivateRoute path={CATEGORY}>
          <AdminLayout>
            <Suspense fallback={<InLineLoader />}>
              <Category />
            </Suspense>
          </AdminLayout>
        </PrivateRoute>
        <PrivateRoute path={ORDERS}>
          <AdminLayout>
            <Suspense fallback={<InLineLoader />}>
              <Orders />
            </Suspense>
          </AdminLayout>
        </PrivateRoute>
        <PrivateRoute path={REVIEWS}>
          <AdminLayout>
            <Suspense fallback={<InLineLoader />}>
              <Reviews />
            </Suspense>
          </AdminLayout>
        </PrivateRoute>
        <PrivateRoute path={COUPONS}>
          <AdminLayout>
            <Suspense fallback={<InLineLoader />}>
              <Coupons />
            </Suspense>
          </AdminLayout>
        </PrivateRoute>
        <PrivateRoute path={TABLES}>
          <AdminLayout>
            <Suspense fallback={<InLineLoader />}>
              <Tables />
            </Suspense>
          </AdminLayout>
        </PrivateRoute>
        <PrivateRoute path={WAITERS}>
          <AdminLayout>
            <Suspense fallback={<InLineLoader />}>
              <Waiters />
            </Suspense>
          </AdminLayout>
        </PrivateRoute>
        <PrivateRoute path={SETTINGS}>
          <AdminLayout>
            <Suspense fallback={<InLineLoader />}>
              <Settings />
            </Suspense>
          </AdminLayout>
        </PrivateRoute>
        <PrivateRoute path={SITE_SETTINGS}>
          <AdminLayout>
            <Suspense fallback={<InLineLoader />}>
              <SiteSettingForm />
            </Suspense>
          </AdminLayout>
        </PrivateRoute>
        <Route component={NotFound} />
      </Switch>
    </Suspense>
  );
};

export default Routes;
