export const START_LOADER = "START_LOADER";
export const STOP_LOADER = "STOP_LOADER";

export const initialState = { counter: 0 };

export function layoutReducer(state = initialState, action) {
    switch (action.type) {
        case START_LOADER:
            return { ...state, counter: state.counter + 1 };
        case STOP_LOADER:
            return { ...state, counter: state.counter - 1 };
        default:
            return state;
    }
}
