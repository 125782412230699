import { getActionTypes, callApiWithActions } from "api";


const loadOrdersActions = getActionTypes("LOAD_ORDERS");

export const initialState = {
    list: {
        loading: false,
        error: false,
        loaded: false,
        data: null,
    }
};

export function ordersReducer(state = initialState, action) {
    switch (action.type) {
        case loadOrdersActions.begin:
            return {
                ...state,
                list: {
                    loading: true,
                    error: false,
                    loaded: false,
                    data: null,
                }
            };
        case loadOrdersActions.success: {
            return {
                ...state,
                list: {
                    loading: false,
                    error: false,
                    loaded: true,
                    data: action.data,
                }
            };
        };
        case loadOrdersActions.error:
            return {
                ...state,
                list: {
                    loading: false,
                    error: true,
                    loaded: false,
                    data: null
                }
            };
        default:
            return state;
    }
}

export const getRestaurantOrders = (dispatch, {restaurantId, params}) => {
    let searchParams = '?';
    for(const key in params) {
        searchParams = searchParams + key + '=' + params[key] + '&';
    }
    callApiWithActions(
        dispatch,
        `/admin/orders/${restaurantId}/search/${searchParams}`,
        {
            method: "GET",
        },
        loadOrdersActions
    );
}

export const listSelector = (state) => state.orders.list;
