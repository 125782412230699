import { lightThemePrimitives } from 'baseui';

export const primitives = {
  ...lightThemePrimitives,
  primaryFontFamily: "'Roboto', sans-serif",
  primary: '#00a79d',
  primary700: '#009188',
};

export const colorsOverrides =  {
  primary: '#00a79d',
  primary700: '#00bd87',
  primary400: '#03D3B5',
  primary009: '#009e7f',
  red400: '#FC5C63',
  red500: '#eb1d0e',
  red700: '#FC6687',
  greyE6: '#E6E6E6',
  grey70: '#707070',
  greybd: '#bdbdbd',
  greyE5: '#E5E9ED',
  greyF2: '#F2F2F2',
  textDark: '#161F6A',
  textGrey: '#757575',
  textDark0D: '#0D1136',
  textLight77: '#77798C',
  textNormal: '#666D92',
  borderE6: '#E6E6E6',
  borderF1: '#f1f1f1',
  backgroundF7: '#f7f7f7',
  lightGreen: '#00d4b5',
  warning: '#f4c243',
  blue400: '#2067fa',
  purple400: '#8A6AFC',
  white: '#ffffff',
};

export const overrides =   {
  name: 'custom-typography',
  typography: {
    primaryFontFamily: "'Roboto', sans-serif",
    font11: {
      fontFamily: "'Roboto', sans-serif",
      fontSize: '11px',
      fontWeight: 400,
      lineHeight: 1.5,
    },
    fontBold11: {
      fontFamily: "'Roboto', sans-serif",
      fontSize: '11px',
      fontWeight: 700,
      lineHeight: 1.5,
    },
    font12: {
      fontFamily: "'Roboto', sans-serif",
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: 1.5,
    },
    fontBold12: {
      fontFamily: "'Roboto', sans-serif",
      fontSize: '12px',
      fontWeight: 700,
      lineHeight: 1.5,
    },
    font13: {
      fontFamily: "'Roboto', sans-serif",
      fontSize: '13px',
      fontWeight: 400,
      lineHeight: 1.5,
    },
    fontBold13: {
      fontFamily: "'Roboto', sans-serif",
      fontSize: '13px',
      fontWeight: 700,
      lineHeight: 1.5,
    },
    font14: {
      fontFamily: "'Roboto', sans-serif",
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: 1.5,
    },
    fontBold14: {
      fontFamily: "'Roboto', sans-serif",
      fontSize: '14px',
      fontWeight: 700,
      lineHeight: 1.5,
    },
    font16: {
      fontFamily: "'Roboto', sans-serif",
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: 1.5,
    },
    fontBold16: {
      fontFamily: "'Roboto', sans-serif",
      fontSize: '16px',
      fontWeight: 700,
      lineHeight: 1.5,
    },
    font18: {
      fontFamily: "'Roboto', sans-serif",
      fontSize: '18px',
      fontWeight: 400,
      lineHeight: 1.5,
    },
    fontBold18: {
      fontFamily: "'Roboto', sans-serif",
      fontSize: '18px',
      fontWeight: 700,
      lineHeight: 1.5,
    },
    font21: {
      fontFamily: "'Roboto', sans-serif",
      fontSize: '21px',
      fontWeight: 400,
      lineHeight: 1.5,
    },
    fontBold21: {
      fontFamily: "'Roboto', sans-serif",
      fontSize: '21px',
      fontWeight: 700,
      lineHeight: 1.5,
    },
    font24: {
      fontFamily: "'Roboto', sans-serif",
      fontSize: '24px',
      fontWeight: 400,
      lineHeight: 1.5,
    },
    fontBold24: {
      fontFamily: "'Roboto', sans-serif",
      fontSize: '24px',
      fontWeight: 700,
      lineHeight: 1.5,
    },
    font30: {
      fontFamily: "'Roboto', sans-serif",
      fontSize: '30px',
      fontWeight: 400,
      lineHeight: 1.5,
    },
    fontBold30: {
      fontFamily: "'Roboto', sans-serif",
      fontSize: '30px',
      fontWeight: 700,
      lineHeight: 1.5,
    },
    font350: {
      fontSize: '14px',
      fontWeight: 700,
      lineHeight: '24px',
    },
    font450: {
      fontSize: '15px',
      fontWeight: 700,
      lineHeight: '22px',
    },
  },
  sizing: {
    scale25: '25px',
    scale30: '30px',
  },
  borders: {
    borderE6: {
      borderColor: '#E6E6E6',
      borderStyle: 'solid',
      borderWidth: '1px',
    },
    borderEA: {
      borderColor: '#eaeaea',
      borderStyle: 'solid',
      borderWidth: '1px',
    },
  },
  buttonBorderRadius: '3px',
  colors:colorsOverrides,
};
