import * as yup from "yup";
import { getActionTypes, callApiWithActions } from "api";

const loadItemsActions = getActionTypes("LOAD_CATEGORIES_ITEMS");
const createItemActions = getActionTypes("CREATE_CATEGOY_ITEM");
const updateItemActions = getActionTypes("UPDATE_CATEGOY_ITEM");
const deleteItemActions = getActionTypes("DELETE_CATEGOY_ITEM");
export const RESET_CREATE_ITEM_ACTION = "RESET_CREATE_ITEM_ACTION";
export const RESET_UPDATE_ITEM_ACTION = "RESET_UPDATE_ITEM_ACTION";
export const SELECT_CATEGORY_ACTION = "SELECT_CATEGORY_ACTION";

const actionInitState = {
    loading: false,
    error: false,
    success: false,
};

export const initialState = {
    category: null,
    list: {
        loading: false,
        error: false,
        loaded: false,
        data: null,
    },
    add: {
        ...actionInitState,
    },
    update: {
        ...actionInitState,
    },
    delete: {
        ...actionInitState
    }
};


export function categoriesItemsReducer(state = initialState, action) {
    switch (action.type) {
        case loadItemsActions.begin:
            return {
                ...state,
                list: {
                    loading: true,
                    error: false,
                    loaded: false,
                    data: null,
                }
            };
        case createItemActions.begin:
            return {
                ...state,
                add: {
                    loading: true,
                    error: false,
                    success: false,
                }
            };
        case updateItemActions.begin:
            return {
                ...state,
                update: {
                    loading: true,
                    error: false,
                    success: false,
                }
            };

        case loadItemsActions.success: {
            return {
                ...state,
                list: {
                    loading: false,
                    error: false,
                    loaded: true,
                    data: action.data,
                }
            };
        };
        case createItemActions.success:
            return {
                ...state,
                add: {
                    loading: false,
                    error: false,
                    success: true,
                }
            };
        case updateItemActions.success:
            return {
                ...state,
                update: {
                    loading: false,
                    error: false,
                    success: true,
                }
            };
        case deleteItemActions.success:
            return {
                ...state,
                delete: {
                    loading: false,
                    error: false,
                    success: true,
                }
            };
        case loadItemsActions.error:
            return {
                ...state,
                list: {
                    loading: false,
                    error: true,
                    loaded: false,
                    data: null
                }
            };
        case createItemActions.error:
            return {
                ...state,
                add: {
                    loading: false,
                    error: true,
                    success: false,
                }
            };
        case updateItemActions.error:
            return {
                ...state,
                update: {
                    loading: false,
                    error: true,
                    success: false,
                }
            };
        case deleteItemActions.error:
            return {
                ...state,
                delete: {
                    loading: false,
                    error: true,
                    success: false,
                }
            };
        case RESET_CREATE_ITEM_ACTION:
            return {
                ...state,
                add: {
                    ...actionInitState,
                }
            };
        case RESET_UPDATE_ITEM_ACTION:
            return {
                ...state,
                update: {
                    ...actionInitState,
                },
                delete: {
                    ...actionInitState,
                }
            };
        case SELECT_CATEGORY_ACTION:
            return {
                ...state,
                category: action.category,
            };
        default:
            return state;
    }
}

export const getCategoriesItems = (dispatch, restaurantId) => {
    callApiWithActions(
        dispatch,
        `/admin/categories/?restaurantId=${restaurantId}`,
        {
            method: "GET",
        },
        loadItemsActions
    );
}

export const createCategoryItem = (dispatch, config) => {
    const { categoryId, data } = config;
    callApiWithActions(
        dispatch,
        `/admin/categories/${categoryId}/items/new`,
        {
            method: "POST",
            headers: {
                'Content-Type': `multipart/form-data;`,
            },
            data
        },
        createItemActions
    );
}

export const updateItem = (dispatch, config) => {
    const { itemId, data } = config;
    callApiWithActions(
        dispatch,
        `/admin/items/${itemId}`,
        {
            method: "PUT",
            headers: {
                'Content-Type': `multipart/form-data;`,
            },
            data
        },
        updateItemActions
    );
}

export const deleteItem = (dispatch, config) => {
    const { itemId } = config;
    callApiWithActions(
        dispatch,
        `/admin/items/${itemId}`,
        {
            method: "DELETE"
        },
        deleteItemActions
    );
}

export const listSelector = (state) => state.categoriesItems.list;
export const addSelector = (state) => state.categoriesItems.add;
export const updateSelector = (state) => state.categoriesItems.update;
export const deleteSelector = (state) => state.categoriesItems.delete;

const DISCOUNT_REGEX = /^[0-9]{0,2}$/;
const PRICE_REGEX =  /^[0-9]{0,19}(\.{0,1}\d{0,2})$/;

export const commonSchema = {
    label: yup.string()
              .max(50, 'Taille maximum de 50 caractères')
              .required("Champ obligatoire"),
    description: yup.string()
                    .max(1000, 'Taille maximum de 1000 caractères'),
    price: yup.string()
                .test("price", "Prix invalide", function (value) {
                    if (value)
                        return PRICE_REGEX.test(value);
                    return true
                })
              .required("Champ obligatoire"),
    discount: yup.string()
                .test("discount",  "La valeur doit être un pourcentage positif", function (value) {
                    if (value)
                        return DISCOUNT_REGEX.test(value);
                    return true
                }),
    topItem: yup.boolean(),
    options: yup.array(),
};

export const creationSchema = yup.object().shape({
    ...commonSchema,
    categories: yup.array()
                   .min(1, "Champ obligatoire").max(1)
                   .required("Champ obligatoire"),
});

export const updateSchema = yup.object().shape({
    ...commonSchema,
});
