import { getActionTypes, callApiWithActions } from "api";


const loadReviewsActions = getActionTypes("LOAD_REVIEWS");

export const initialState = {
    list: {
        loading: false,
        error: false,
        loaded: false,
        data: null,
    }
};

export function reviewsReducer(state = initialState, action) {
    switch (action.type) {
        case loadReviewsActions.begin:
            return {
                ...state,
                list: {
                    loading: true,
                    error: false,
                    loaded: false,
                    data: null,
                }
            };
        case loadReviewsActions.success: {
            return {
                ...state,
                list: {
                    loading: false,
                    error: false,
                    loaded: true,
                    data: action.data,
                }
            };
        };
        case loadReviewsActions.error:
            return {
                ...state,
                list: {
                    loading: false,
                    error: true,
                    loaded: false,
                    data: null
                }
            };
        default:
            return state;
    }
}

export const getRestaurantReviews = (dispatch, restaurantId) => {
    callApiWithActions(
        dispatch,
        `/admin/restaurants/${restaurantId}/reviews`,
        {
            method: "GET",
        },
        loadReviewsActions
    );
}

export const listSelector = (state) => state.reviews.list;
